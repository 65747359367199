import React from 'react'
import Layout from '../components/layout'

const NotFoundPage = () => (
  <Layout title="404 - Not Found">
    <div className="mw7-m mw8-l center mt5 mb7 ph3">
      <h1 className="mv0 pv4">NOT FOUND</h1>
      <p className="mv0 pv2">You just hit a route that doesn&#39;t exist... the sadness.</p>
    </div>
  </Layout>
)

export default NotFoundPage
